@tailwind base;
@tailwind components;
@tailwind utilities;

body * {
  margin: 0;
  font-family: "Poppins", Impact, Haettenschweiler, "Arial Narrow Bold",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
